
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404 | Page not found" />
      <Container maxWidth='lg' style={{padding: `6rem`}}>
        <Typography variant="h2" component="h2" align="center">
          404 | Page not found :(
        </Typography>
        <Typography variant="h2" component="h2" align="center">
          The page you requested does not exist
        </Typography>
      </Container>
    </Layout>
  )
}

export default NotFoundPage